exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-admin-login-index-tsx": () => import("./../../../src/templates/admin-login/index.tsx" /* webpackChunkName: "component---src-templates-admin-login-index-tsx" */),
  "component---src-templates-dashboard-index-tsx": () => import("./../../../src/templates/dashboard/index.tsx" /* webpackChunkName: "component---src-templates-dashboard-index-tsx" */),
  "component---src-templates-game-type-detail-index-tsx": () => import("./../../../src/templates/game-type-detail/index.tsx" /* webpackChunkName: "component---src-templates-game-type-detail-index-tsx" */),
  "component---src-templates-game-types-overview-index-tsx": () => import("./../../../src/templates/game-types-overview/index.tsx" /* webpackChunkName: "component---src-templates-game-types-overview-index-tsx" */),
  "component---src-templates-incident-detail-index-tsx": () => import("./../../../src/templates/incident-detail/index.tsx" /* webpackChunkName: "component---src-templates-incident-detail-index-tsx" */),
  "component---src-templates-incident-logs-index-tsx": () => import("./../../../src/templates/incident-logs/index.tsx" /* webpackChunkName: "component---src-templates-incident-logs-index-tsx" */),
  "component---src-templates-incidents-overview-index-tsx": () => import("./../../../src/templates/incidents-overview/index.tsx" /* webpackChunkName: "component---src-templates-incidents-overview-index-tsx" */),
  "component---src-templates-machine-categories-overview-machine-categories-overview-template-tsx": () => import("./../../../src/templates/machine-categories-overview/machine-categories-overview-template.tsx" /* webpackChunkName: "component---src-templates-machine-categories-overview-machine-categories-overview-template-tsx" */),
  "component---src-templates-machine-category-machine-category-create-template-tsx": () => import("./../../../src/templates/machine-category/machine-category-create-template.tsx" /* webpackChunkName: "component---src-templates-machine-category-machine-category-create-template-tsx" */),
  "component---src-templates-machine-category-machine-category-template-tsx": () => import("./../../../src/templates/machine-category/machine-category-template.tsx" /* webpackChunkName: "component---src-templates-machine-category-machine-category-template-tsx" */),
  "component---src-templates-machine-detail-index-tsx": () => import("./../../../src/templates/machine-detail/index.tsx" /* webpackChunkName: "component---src-templates-machine-detail-index-tsx" */),
  "component---src-templates-machine-status-machine-status-template-tsx": () => import("./../../../src/templates/machine-status/machine-status-template.tsx" /* webpackChunkName: "component---src-templates-machine-status-machine-status-template-tsx" */),
  "component---src-templates-machines-overview-machines-overview-machines-overview-tsx": () => import("./../../../src/templates/machines-overview/machines-overview/machines-overview.tsx" /* webpackChunkName: "component---src-templates-machines-overview-machines-overview-machines-overview-tsx" */),
  "component---src-templates-order-detail-index-tsx": () => import("./../../../src/templates/order-detail/index.tsx" /* webpackChunkName: "component---src-templates-order-detail-index-tsx" */),
  "component---src-templates-orders-overview-index-tsx": () => import("./../../../src/templates/orders-overview/index.tsx" /* webpackChunkName: "component---src-templates-orders-overview-index-tsx" */),
  "component---src-templates-player-detail-index-tsx": () => import("./../../../src/templates/player-detail/index.tsx" /* webpackChunkName: "component---src-templates-player-detail-index-tsx" */),
  "component---src-templates-players-overview-index-tsx": () => import("./../../../src/templates/players-overview/index.tsx" /* webpackChunkName: "component---src-templates-players-overview-index-tsx" */),
  "component---src-templates-prize-categories-overview-index-tsx": () => import("./../../../src/templates/prize-categories-overview/index.tsx" /* webpackChunkName: "component---src-templates-prize-categories-overview-index-tsx" */),
  "component---src-templates-prize-category-detail-index-tsx": () => import("./../../../src/templates/prize-category-detail/index.tsx" /* webpackChunkName: "component---src-templates-prize-category-detail-index-tsx" */),
  "component---src-templates-prize-detail-index-tsx": () => import("./../../../src/templates/prize-detail/index.tsx" /* webpackChunkName: "component---src-templates-prize-detail-index-tsx" */),
  "component---src-templates-prizes-overview-index-tsx": () => import("./../../../src/templates/prizes-overview/index.tsx" /* webpackChunkName: "component---src-templates-prizes-overview-index-tsx" */),
  "component---src-templates-tenant-detail-tenant-detail-tsx": () => import("./../../../src/templates/tenant-detail/tenant-detail.tsx" /* webpackChunkName: "component---src-templates-tenant-detail-tenant-detail-tsx" */),
  "component---src-templates-tenants-overview-index-tsx": () => import("./../../../src/templates/tenants-overview/index.tsx" /* webpackChunkName: "component---src-templates-tenants-overview-index-tsx" */)
}

