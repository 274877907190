import {Permissions, PERMISSIONS_MAP} from './permissions'

export enum Roles {
	ELAUT_ADMIN = 'elautAdmin',
	ELAUT_ARCADE_PLATFORM_MANAGER = 'elautArcadePlatformManager',
	ELAUT_READ_ONLY = 'elautReadOnly',
	ADMIN = 'admin',
	ONLINE_ARCADE_MANAGER = 'onlineArcadeManager',
	CUSTOMER_SERVICE = 'customerService',
	ARCADE_EMPLOYEE = 'arcadeEmployee',
	READ_ONLY = 'readOnly',
}

const roleToPermissionsMap = new Map<Roles, Permissions[]>([
	// Elaut Roles
	[Roles.ELAUT_ADMIN, Array.from(PERMISSIONS_MAP.values()) as Permissions[]],
	[
		Roles.ELAUT_ARCADE_PLATFORM_MANAGER,
		Array.from(PERMISSIONS_MAP.values()).filter(
			// No write access on tenant and game-type actions
			(p: Permissions) =>
				![
					Permissions.TENANTS_WRITE,
					Permissions.TENANT_SETTINGS_WRITE,
					Permissions.GAMETYPES_READ,
					Permissions.GAMETYPES_WRITE,
					Permissions.INCIDENTS_READ,
					Permissions.INCIDENTS_WRITE,
					Permissions.MACHINE_CATEGORIES_READ,
					Permissions.MACHINE_CATEGORIES_WRITE,
				].includes(p)
		) as Permissions[],
	],
	[
		Roles.ELAUT_READ_ONLY,
		// Only read access
		Array.from(PERMISSIONS_MAP.values()).filter(
			(p: Permissions) =>
				p.includes('Read') && ![Permissions.INCIDENTS_READ].includes(p)
		) as Permissions[],
	],

	// Tenant Roles
	[
		Roles.ADMIN,
		// All permissions, except for game-type permissions
		Array.from(PERMISSIONS_MAP.values()).filter(
			(p: Permissions) =>
				!p.includes('game types') && ![Permissions.INCIDENTS_WRITE].includes(p)
		) as Permissions[],
	],
	[
		Roles.ONLINE_ARCADE_MANAGER,
		Array.from(PERMISSIONS_MAP.values()).filter(
			// No write access on tenant and game-type actions
			(p: Permissions) =>
				![
					Permissions.TENANTS_WRITE,
					Permissions.TENANT_SETTINGS_WRITE,
					Permissions.GAMETYPES_READ,
					Permissions.GAMETYPES_WRITE,
					Permissions.INCIDENTS_READ,
					Permissions.INCIDENTS_WRITE,
					Permissions.MACHINE_CATEGORIES_READ,
					Permissions.MACHINE_CATEGORIES_WRITE,
				].includes(p)
		) as Permissions[],
	],
	[
		Roles.CUSTOMER_SERVICE,
		// No write access on tenant, game-type, prize and shop actions
		Array.from(PERMISSIONS_MAP.values()).filter(
			(p: Permissions) =>
				![
					Permissions.TENANTS_WRITE,
					Permissions.TENANT_SETTINGS_WRITE,
					Permissions.GAMETYPES_READ,
					Permissions.GAMETYPES_WRITE,
					Permissions.PRIZES_WRITE,
					Permissions.PRIZE_CATERGORIES_WRITE,
					Permissions.SHOP_WRITE,
					Permissions.INCIDENTS_READ,
					Permissions.INCIDENTS_WRITE,
				].includes(p)
		) as Permissions[],
	],
	[
		Roles.ARCADE_EMPLOYEE,
		// Only full access on order actions
		Array.from(PERMISSIONS_MAP.values()).filter((p: Permissions) =>
			p.includes('orders')
		) as Permissions[],
	],
	[
		Roles.READ_ONLY,
		// Only read access, except for game-types
		Array.from(PERMISSIONS_MAP.values()).filter(
			(p: Permissions) =>
				p.includes('Read') &&
				![Permissions.GAMETYPES_READ, Permissions.INCIDENTS_READ].includes(p)
		) as Permissions[],
	],
])

export const roleHasPermission = (
	role: Roles,
	permission: Permissions
): boolean => {
	return roleToPermissionsMap.get(role)?.includes(permission)
}
